
import { Button } from '@/Components/ui/button';
import { cn } from '@/lib/utils';
import { Icon } from '@iconify/react';
import Carousel from 'react-multi-carousel';

const LeftArrow = ({ onClick, ...rest }) => {
    const { onMove, carouselState: { currentSlide, deviceType } } = rest;
    return <Button variant="icon" className="absolute p-2 group -left-2 !z-[100]" onClick={() => onClick()} ><Icon className="group-hover:text-teal-600 text-3xl" icon="icons8:chevron-left-round" /></Button>
}
const RightArrow = ({ onClick, ...rest }) => {
    const { onMove, carouselState: { currentSlide, deviceType } } = rest;
    return <Button variant="icon" className="absolute p-2 group -right-2 !z-[100]" onClick={() => onClick()} ><Icon className="group-hover:text-teal-600 text-3xl" icon="icons8:chevron-right-round" /></Button>
}

export default function ProductColorsLine({ items, onColorSelect, className }) {

    const responsive = {
        desktop: {
            breakpoint: { max: 5000, min: 0 },
            items: 7,
            slidesToSlide: 5
        }
    };
    return <Carousel className={cn("w-full block my-2")} responsive={responsive} containerClass={cn("flex flex-row gap-1 justify-center items-center", className)}
        customLeftArrow={<LeftArrow />} customRightArrow={<RightArrow />}>
        {items.map((color, index) => (
            <div onClick={() => onColorSelect(color.hex)} className="size-6 z-20 border cursor-pointer hover:border-teal-600 rounded-full" key={color.name} style={{ backgroundColor: color.hex }}></div>
        ))}
    </Carousel>

}
